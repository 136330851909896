import Game from '../scenes/Game'
import phaserGame from '../PhaserGame'
import { Box, Fab } from '@mui/material'
import Up from '@mui/icons-material/ArrowCircleUpRounded'
import Left from '@mui/icons-material/ArrowCircleLeft'
import Right from '@mui/icons-material/ArrowCircleRight'
import Down from '@mui/icons-material/ArrowCircleDown'
export default function JoyStick() {
  const game = phaserGame.scene.keys.game as Game

  const navEnd = (key: string) => () => {
    game.input.keyboard.emit(`keyup-${key}`)
  }
  const navStart = (key: string) => () => {
    game.input.keyboard.emit(`keydown-${key}`)
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          width: {
            xs: '50%',
            sm: '30%',
            md: '15%',
            lg: '10%',
          },
          justifyContent: 'center',
          alignItems: 'center',
        }}
        display="flex"
        flexDirection="column"
      >
        <Fab
          color="primary"
          onMouseDown={navStart('W')}
          onMouseUp={navEnd('W')}
          onMouseLeave={navEnd('W')}
          onTouchStart={navStart('W')}
          onTouchEnd={navEnd('W')}
          onTouchCancel={navEnd('W')}
          onTouchEndCapture={navEnd('W')}
          onTouchCancelCapture={navEnd('W')}
          onContextMenu={(e) => {
            e.preventDefault()
            return false
          }}
        >
          <Up />
        </Fab>
        <Box display="flex" width={1} alignItems="center" justifyContent="space-between">
          <Fab
            color="primary"
            onMouseDown={navStart('A')}
            onMouseUp={navEnd('A')}
            onMouseLeave={navEnd('A')}
            onTouchStart={navStart('A')}
            onTouchEnd={navEnd('A')}
            onTouchCancel={navEnd('A')}
            onTouchEndCapture={navEnd('A')}
            onTouchCancelCapture={navEnd('A')}
            onContextMenu={(e) => {
              e.preventDefault()
              return false
            }}
          >
            <Left />
          </Fab>
          <Fab
            color="primary"
            onMouseDown={navStart('D')}
            onMouseUp={navEnd('D')}
            onMouseLeave={navEnd('D')}
            onTouchStart={navStart('D')}
            onTouchEnd={navEnd('D')}
            onTouchCancel={navEnd('D')}
            onTouchEndCapture={navEnd('D')}
            onTouchCancelCapture={navEnd('D')}
            onContextMenu={(e) => {
              e.preventDefault()
              return false
            }}
          >
            <Right />
          </Fab>
        </Box>
        <Fab
          color="primary"
          onMouseDown={navStart('S')}
          onMouseUp={navEnd('S')}
          onMouseLeave={navEnd('S')}
          onTouchStart={navStart('S')}
          onTouchEnd={navEnd('S')}
          onTouchCancel={navEnd('S')}
          onTouchEndCapture={navEnd('S')}
          onTouchCancelCapture={navEnd('S')}
          onContextMenu={(e) => {
            e.preventDefault()
            return false
          }}
        >
          <Down />
        </Fab>

        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: -120,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            flexDirection: 'column',
            width: 1,
          }}
        >
          <Box sx={{ width: '56px' }}></Box>

          <Fab
            color="primary"
            onMouseDown={navStart('E')}
            onTouchStart={navStart('E')}
            onContextMenu={(e) => {
              e.preventDefault()
              return false
            }}
            sx={{ mb: 2 }}
          >
            E
          </Fab>
          <Fab
            color="primary"
            onMouseDown={navStart('R')}
            onTouchStart={navStart('R')}
            onContextMenu={(e) => {
              e.preventDefault()
              return false
            }}
          >
            R
          </Fab>
        </Box>
      </Box>
    </>
  )
}

/* 

              onMouseDown={() => game?.input?.keyboard?.emit(`keydown-S`)}
              onMouseUp={() => game?.input?.keyboard?.emit(`keyup-S`)}
              onMouseLeave={() => game?.input?.keyboard?.emit(`keyup-S`)}
*/
